<template>
    <div class="page-wrap">
      <div class="page-main flex-column">
        <SubNav style="position: relative; z-index: 2;" :router="true" :nav="[{name:'概览模式',value:'/home/overview'},{name:'图谱模式',value:'/home/graph'}]"></SubNav>
        <div class="main padding-20"><router-view></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import SubNav from '@/components/SubNav.vue'
export default {
  name: 'Entry',
  components: { SubNav },
  data() {
    return {
     nav:null
    }
  },
  created(){
   
  },
  methods:{
    
    
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
